@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  @apply min-h-full;
}

ul,
menu {
  @apply list-disc p-4;
}

ol {
  @apply list-decimal p-4;
}

.annotation-form select,
textarea,
input[type="text"],
input[type="number"] {
  @apply w-full;
}

.annotation-form input[type="radio"],
input[type="checkbox"] {
  @apply ml-1;
}

/*
Ensure react-select does not get a weird looking box around the inner input from Tailwind styles
https://romansorin.com/blog/disabling-the-tailwind-input-ring
*/
.react-select__input {
  @apply focus:ring-0 focus:ring-offset-0;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
